export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🔍Поиск. . ."])}
      },
      "en": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["🔍Search. . ."])}
      }
    }
  })
}
