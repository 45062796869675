export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ru": {
        "hiim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["👋Привет, я Даниил Савченков."])},
        "im": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мне"])},
        "yearsold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["."])},
        "myprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это некоторые из моих проектов, которые расскажут о моей деятельности."])}
      },
      "en": {
        "hiim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["👋Hi, Im Daniil Savchenkov!"])},
        "im": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I am"])},
        "yearsold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years old."])},
        "myprojects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are a few of my projects that may be about my activities."])}
      }
    }
  })
}
